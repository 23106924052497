import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const REGEX_SPECIAL_CHARACTERS = /[|\\{}()[\]^$+*?.]/g;

const readCookie = () => {
  const name = process.env.REACT_APP_ACCOUNT_COOKIE;
  var v = cookies.get(name);
  return v ? v : {};
};

const setCookie = (name, data, time) => {
  if (process.env.REACT_DOMAIN_URL) {
    cookies.set(name, data, {
      path: '/',
      domain: process.env.REACT_DOMAIN_URL,
      expires: time,
      secure: true,
      sameSite: true,
    });
  } else {
    cookies.set(name, data, {
      path: '/',
      expires: time,
    });
  }
};

const deleteCookie = (name) => {
  if (process.env.REACT_DOMAIN_URL) {
    cookies.remove(name, {
      path: '/',
      domain: process.env.REACT_DOMAIN_URL || '.zapr.in',
    });
  } else {
    cookies.remove(name, {
      path: '/',
    });
  }
};

const parseBool = (value = '') => {
  value = value.trim().toLowerCase();
  return value === 'true' || value === 1;
};

const getRequestPath = (path, pathParams, queryParams = []) => {
  let url = path;
  for (const prop in pathParams) {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(`\:${prop}`, 'g');
    url = url.replace(regex, pathParams[prop] || 0);
  }
  if (queryParams.length > 0) {
    if (url.lastIndexOf('?') !== url.length - 1) {
      url += '?';
    }
    queryParams.forEach((param, index) => {
      if (index !== 0) {
        url += '&';
      }
      url += `${param.key}=${param.value}`;
    });
  }
  return url;
};

/**
 *
 * @param {Array.<{key: string, value: string|number|boolean, binary?:boolean}>} data
 * @returns {FormData}
 */
const createFormData = (data) => {
  const form = new FormData();
  data.forEach(({ key, value, binary }, index) => {
    if (binary) {
      form.append(key, value);
    } else if (typeof value === 'object') {
      form.append(
        key,
        new Blob([JSON.stringify(value)], {
          type: 'application/json',
        })
      );
    } else if (
      typeof value === 'number' ||
      typeof value === 'string' ||
      typeof value === 'bigint' ||
      typeof value === 'boolean'
    ) {
      form.set(key, value);
    }
  });
  return form;
};

const toISOString = (seconds, includeMS = true) => {
  if (seconds > 3600) {
    return new Date(seconds * 1000)
      .toISOString()
      .substr(11, includeMS ? 12 : 8);
  } else {
    return new Date(seconds * 1000).toISOString().substr(14, includeMS ? 9 : 5);
  }
};

const arrGroupBy = function (arr, key) {
  return arr.reduce(function (rv, x) {
    if (x[key] !== undefined) {
      if (!rv[x[key]]) {
        rv[x[key]] = [];
      }
      rv[x[key]].push(x);
    }
    return rv;
  }, {});
};

const uniqBy = (arr, keys) => {
  var seen = {};
  return arr.filter(function (item) {
    let k = keys.map((key) => item[key]).join('$$$$');
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
};

const toFixed = (value, decimalPlaces = 2) => {
  return Number(parseFloat(value).toFixed(decimalPlaces));
};

const hexToRGB = (h) => {
  if (!h) {
    return {};
  }
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = parseInt(h[1], 16);
    g = parseInt(h[2], 16);
    b = parseInt(h[3], 16);

    // 6 digits
  } else if (h.length === 7) {
    r = parseInt(h[1] + h[2], 16);
    g = parseInt(h[3] + h[4], 16);
    b = parseInt(h[5] + h[6], 16);
  }
  return { r, g, b };
};

const handleTokenExpiry = () => {
  console.log('Token expired, redirecting to accounts');
  let authPortalPath = `${process.env.PUBLIC_URL}${process.env.PUBLIC_URL === '/' ? '' : '/'
    }account/login`;
  const url = `${authPortalPath}?returnUrl=${window.location.href}`;
  deleteCookie('zapr_user');
  if (window.location.href.indexOf('/account') === -1) {
    window.open(
      url || window.location.origin + process.env.PUBLIC_URL,
      '_self'
    );
  }
};

const escapeStringRegexp = (string) => {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }

  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return string
    .replace(REGEX_SPECIAL_CHARACTERS, '\\$&')
    .replace(/-/g, '\\x2d');
};

const hasSpecialChar = (string) => {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }

  return !!string.match(REGEX_SPECIAL_CHARACTERS);
};

const Utils = {
  readCookie,
  setCookie,
  deleteCookie,
  parseBool,
  getRequestPath,
  createFormData,
  toISOString,
  arrGroupBy,
  toFixed,
  hexToRGB,
  handleTokenExpiry,
  uniqBy,
  escapeStringRegexp,
  hasSpecialChar,
};

export default Utils;
