import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Radio } from "antd";
import { useDispatch } from "react-redux";
import { arrowBackCircleOutline } from "ionicons/icons";
import { API } from "../../common/constants/api.constant";
import useHTTP from "../../common/hooks/http";
import useToastr from "../../common/hooks/toastr";
import Utils from "../../common/services/utils.service";
import { AuthContext } from "../../common/context/auth.context";
import { updateAuthInfo } from "../../../app.reducer";
import logo from "../../../assets/logo.jpeg";
import "./login.scss";
import { IonIcon } from "@ionic/react";

export const Login = () => {
  const [type, setType] = useState("email");
  const [otpSent, setOtpSent] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const toastr = useToastr();
  const $http = useHTTP();
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authContext.userLoggedIn) {
      $http
        .get(API.GET.GET_USER_INFO)
        .then((res) => {
          navigateTo();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function navigateTo() {
    let params = new URLSearchParams(window.location.search);
    let returnUrl = params.get("returnUrl");
    window.open(
      returnUrl || window.location.origin + process.env.PUBLIC_URL,
      "_self"
    );
  }

  const goBack = () => {
    setOtpSent(false);
  };

  const setCookie = (data) => {
    let d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    Utils.setCookie("ss_user", JSON.stringify(data), d);
  };

  const onError = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const isFormInvalid = (errors) => {
  //   const items = errors.filter(
  //     (err) => err.errors?.length > 0 || err.warnings?.length > 0
  //   );
  //   return items.length > 0;
  // };

  const login = (e) => {
    
    e.preventDefault();

    var validationFields = ['password']
    if (type === "phone") {
      validationFields.push('phone')
    }
    else if (type === "email") {
      validationFields.push('email')
    }
    form.validateFields(validationFields);
    
    const data = { ...form.getFieldsValue() };

    var req = {};
    if (!otpSent) {
      req.password = data.password;
    } else {
      req.otp = data.otp;
    }
    if (type === "phone") {
      if (!data.phone || (!otpSent && !data.password)) {
        return
      }
      req.type = 'phone';
      req.phone = `+91${data.phone}`;
    }
    if (type === "email") {
      if (!data.email || (!otpSent && !data.password)) {
        return
      }
      req.type = 'email';
      req.email = `${data.email}`;
    }
    $http
      .post(`${API.POST.LOGIN}`, {
        ...req
      })
      .then((res) => {
        dispatch(
          updateAuthInfo({
            ...res,
          })
        );
        setCookie(res);
        navigateTo();
      })
      .catch((err) => {
        if (err && err.data && err.data.message) {
          toastr.error(err.data.message);
        }
      });
  };
  
  const sendOtp = (e) => {
    e.preventDefault();

    var validationFields = []
    if (type === "phone") {
      validationFields.push('phone')
    }
    else if (type === "email") {
      validationFields.push('email')
    }
    form.validateFields(validationFields);

    const data = {...form.getFieldsValue() }; 

    var req = {};
    if (type === "phone") {
      if (!data.phone) {
        return
      }
      req.type = "phone";
      req.phone= `+91${data.phone}`;
    }
    if (type === "email") {
      if (!data.email) {
        return
      }
      req.type = "email";
      req.email= `${data.email}`;
    }
    
    $http
      .post(`${API.POST.SEND_OTP}`, {
        // phone: `+91${form.getFieldValue("phone")}`,
        // email: `${form.getFieldValue("email")}`,
        ...req,
        type,
      })
      .then((res) => {
        setOtpSent(true);
      })
      .catch((err) => {
        setOtpSent(false);
        if (err && err.data && err.data.message) {
          toastr.error(err.data.message);
        }
      });
  };

  const redirectTo = (to) => {
    history.push(`/account/${to}`);
  };

  return (
    <div className="account-form text-center">
      <div className="row">
          <div className="col-sm-12 col-xs-12 back-btn">
            <button className="link" onClick={goBack} hidden={!otpSent}>
              <span className="icon">
                <IonIcon icon={arrowBackCircleOutline}></IonIcon>
              </span>{" "}
              Back
            </button>
          </div>
      </div>
      <div className="brand-title">
        <div className="brand-name">
          <img
            className="navigation__logo"
            style={{ marginRight: 50, height: "90px", width: "180px" }}
            src={logo}
            alt="logo"
          />
        </div>
        {/* <div>Signature Surfaces</div> */}
      </div>
      <div className="row m-4">
        <div className="col-sm-12 col-xs-12 text-center font-3xl-semibold">
          Login to Dashboard
        </div>
        <div className="col-sm-12 col-xs-12 text-center font-lg-regular subtitle">
          Enter your email and password below
        </div>
      </div>
      <Form form={form} name="basic" layout="vertical" onFinishFailed={onError}>
        <Form.Item>
          <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
            <Radio value="email"> Email </Radio>
            <Radio value="phone"> Mobile </Radio>
          </Radio.Group>
        </Form.Item>
        {type === "email" && (
          <>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                { type: "email", message: "The input is not valid E-mail!" },
              ]}
              hidden={otpSent}
            >
              <Input />
            </Form.Item>
          
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
              hidden={otpSent}
            >
              <Input.Password />
            </Form.Item>

          </>
        )}

        {type === "phone" && (
          <>
            <Form.Item
              label="Phone Number"
              name="phone"
              placeholder="Enter phone number"
              rules={[
                { required: true, message: "Please enter your mobile!" },
                {
                  pattern: /^\d{10}$/,
                  message: "Please enter a valid mobile number",
                },
              ]}
              hidden={otpSent}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
              hidden={otpSent}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}

            <Form.Item
                label="OTP"
                name="otp"
                placeholder="Enter 6 digit OTP"
                rules={[
                  { required: true, message: "Please enter 6 digit OTP!" },
                  { pattern: /^\d{6}$/, message: "Enter 6 digit OTP" },
                ]}
                hidden={!otpSent}
              >
                <Input />
              </Form.Item>
          
            <Form.Item className="text-left">
              <button
                type="submit"
                className="btn btn-submit btn-login font-2xl-regular"
                onClick={login}
              >
                Sign In
              </button>
            </Form.Item>
            {!otpSent && (
              <Form.Item className="text-left">
                <button
                  type="submit"
                  className="btn btn-submit btn-login font-2xl-regular"
                  onClick={sendOtp}
                >
                  Sign in with OTP
                </button>
              </Form.Item>
            )}

            {/* {otpSent && (
              <Form.Item
                label="OTP"
                name="otp"
                placeholder="E  nter 6 digit OTP"
                rules={[
                  { required: true, message: "Please enter 6 digit OTP!" },
                  { pattern: /^\d{6}$/, message: "Enter 6 digit OTP" },
                ]}
              >
                <Input />
              </Form.Item>
            )} */}

        <Form.Item className="text-left">
          <span className="subtitle">Don't have an account?</span>
          <button
            className="btn btn-link p-0 ml-2 font-2xl-regular"
            onClick={() => redirectTo("signup")}
          >
            Sign up
          </button>
        </Form.Item>
        <Form.Item className="text-left">
          <button
            type="button"
            className="btn btn-link btn-forgot font-2xl-regular"
            onClick={() => redirectTo("forgotpassword")}
          >
            Forgot password ?
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
