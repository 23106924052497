import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form, Input, Upload, Select } from 'antd';
import { IonIcon } from '@ionic/react';
import { arrowBackCircleOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';

import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import {
    showLoader,
    hideLoader,
  } from '../../../app.reducer';

import './add.scss';


const { TextArea } = Input;
const Option = Select.Option;

const AddProduct = () => {
    const history = useHistory();
    const toastr = useToastr();
    const [form] = Form.useForm();
    const {type, category, productId } = useParams();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const [fileList, setFileList] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categoryInfo, setCategoryInfo] = useState({});
    const [defaults, setDefaults] = useState({});
    const [newBrand, setNewBrand] = useState(null);
    const [rawData, setRawData] = useState(null);
    const dispatch = useDispatch();
    const formRef = useRef(null);

  useEffect(() => {
    fetchDefaultOptions();
    if (productId) {
        fetchProducts();
    } else {
        fetchCategories();
    }
   
  }, []);

  const fetchProducts = () => {
    dispatch(showLoader());
    $http
        .get(`${process.env.REACT_APP_API_URL}/${type}/products/${productId}/`)
        .then((res) => {
            console.log(form);
            form.setFieldsValue({...res});
            setRawData({...res});
            setFileList(res.tile_images.map((tile, index) => {
                return {
                    uid: index,
                    url: tile.image,
                    status: 'done',
                    name: index,
                    id: tile.id,
                    response: {
                        image_url: tile.image
                    }
                }
                
            }));
            dispatch(hideLoader());
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
            dispatch(hideLoader());
        });
  }

  const fetchDefaultOptions = () => {
    $http
      .get(`${process.env.REACT_APP_API_URL}/${type}/defaults/?category_id=${category}`)
      .then((res) => {
        const defaults = {};
        defaults.application_type = res.application_type.map(ele => {
            return {
                value: ele.id, 
                label: ele.type,
            }
        });
        defaults.body_type = res.body_type.map(ele => {
            return {
                value: ele.id, 
                label: ele.type,
            }
        });
        defaults.make_type = [{
            value: res?.make_type?.id ?? null, 
            label: res?.make_type?.type ?? null,
        }];
        defaults.surface_type = res.surface_type.map(ele => {
            return {
                value: ele.id, 
                label: ele.type,
            }
        });
        defaults.quality = res.quality.map(ele => {
            return {
                value: ele.id, 
                label: ele.type,
            }
        });
        console.log(defaults);
        setDefaults(defaults);
        // const brands = res.brands.map(brand => {
        //     return {
        //         value: brand,
        //         label: brand,
        //     }
        // })
        // setBrands(res.brands.sort((a, b) => a.last_nom.localeCompare(b.last_nom)));
        setBrands(res.brands.sort());
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
      });
  }

  const fetchCategories = () => {
    $http
        .get(`${process.env.REACT_APP_API_URL}/${type}/categories/${category}/`)
        .then((res) => {
            const values = {
                make_type: res.make_type,
                application_type: res.application_type,
                body_type: res.body_type,
                width_in_inches: res.width_in_inches,
                width_in_mm: res.width_in_mm,
                height_in_mm: res.height_in_mm,
                height_in_inches: res.height_in_inches,
                quality: res.quality,
                packing_per_box: res.default_packing_per_box,
            };
            form.setFieldsValue(values);
            setRawData(values);
            // const data = res.results.map(category => {
            //     return {
            //         value: category.id,
            //         label: category.name
            //     }
            // })
            // setCategories(data);
        })
        .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
        });
  }

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addProduct = (values) => {
    const tile_images = fileList?.map((ele, index) => {
        return {
            image: ele.response?.image_url ?? ele.url ,
            ordering: index,
            id: ele.id
        }
    });
    const data = { 
      ...values,
      category: category,
      tile_images: tile_images || []
    };
    dispatch(showLoader());
    if (productId) {
        $http
        .patch(`${process.env.REACT_APP_API_URL}/${type}/products/${productId}/`, data)
        .then((res) => {
            const message = `Product updated successfully!`;
            toastr.info(message);
            dispatch(hideLoader());
            history.push(`/${type}/${category}/products`);
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
            dispatch(hideLoader());
        });
    } else {
        $http
        .post(`${process.env.REACT_APP_API_URL}/${type}/products/`, data)
        .then((res) => {
            const message = `Product added successfully!`;
            toastr.info(message);
            dispatch(hideLoader());
            history.push(`/${type}/${category}/products`);
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
            dispatch(hideLoader());
        });
    }
    
  };

  const deleteProduct = () => {
    dispatch(showLoader());
    $http
        .deleteReq(`${process.env.REACT_APP_API_URL}/${type}/products/${productId}/`, {})
        .then((res) => {
            const message = `Product deleted successfully!`;
            toastr.info(message);
            dispatch(hideLoader());
            history.push(`/${type}/${category}/products`);
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
            dispatch(hideLoader());
        });
  }

  const onChange = ({ fileList: newFileList, event }) => {
    setFileList(newFileList);
    if (event?.percent === 100) {
        dispatch(hideLoader());
    }
  };

  const onDrop = () => {
    dispatch(showLoader());
  }

  const resetForm = () => {
    form.resetFields();
    form.setFieldsValue(rawData);
    setFileList([]);
    toastr.info("Form reset successfully!");
  }

  const goBack = () => {
    history.goBack();
  }

  const brandSelectOption = brands.map(brand => (
    <Option key={brand} value={brand}>{brand.toUpperCase()}</Option>
  ));

  return (
    <>
      <div className="product-form">
        <div className="row">
            <div className="col-sm-12 col-xs-12 back-btn">
                <button className="link" onClick={goBack}><span className="icon"><IonIcon icon={arrowBackCircleOutline}></IonIcon></span> Back</button>
            </div>
        </div>
        <div className="row my-4 px-0">
          <div className="col-sm-12 col-xs-12 font-3xl-semibold">Add Product</div>
        </div>
        
        <Form
            ref={formRef}
            name="basic"
            layout="vertical"
            form={form}
            onFinish={addProduct}
            onFinishFailed={onError}
        >
            <Form.Item
                label="Product Name"
                name="name"
                rules={[{ required: true, message: 'Please enter product name!' }]}
            >
                <Input placeholder="Enter Product Name" />
            </Form.Item>
            <Form.Item
                label="Brand"
                name="brand"
                rules={[{ required: true, message: 'Please select or enter brand!' }]}
            >
                <Select
                    showSearch
                    placeholder="Select or Enter Brand"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    onSearch={(e)=> {
                        if(e.length > 0){
                            setNewBrand(e);
                        }
                    }}
                >
                    {
                        newBrand && !brands.includes(newBrand) &&
                            <Option key={newBrand}
                                    value={newBrand}>{newBrand.toUpperCase()}</Option>
                    }
                    {brandSelectOption}
                </Select>
            </Form.Item>
            <Form.Item
                label="Application Type"
                name="application_type"
                rules={[{ required: true, message: 'Please enter application type!' }]}
            >
                <Select
                    placeholder="Select Application Type"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={defaults?.application_type ?? []}
                />
            </Form.Item>
            <Form.Item
                label="Make Type"
                name="make_type"
            >
                <Select
                    placeholder="Select Make Type"
                    optionFilterProp="children"
                    disabled={true}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={defaults?.make_type ?? []}
                />
            </Form.Item>
            <Form.Item
                label="Surface Type"
                name="surface_type"
                rules={[{ required: true, message: 'Please enter surface type!' }]}
            >
                <Select
                    placeholder="Select Surface Type"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={defaults?.surface_type ?? []}
                />
            </Form.Item>
            <Form.Item
                label="Body Type"
                name="body_type"
                rules={[{ required: true, message: 'Please enter body type!' }]}
            >
                <Select
                    placeholder="Select Body Type"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={defaults?.body_type ?? []}
                />
            </Form.Item>
            <Form.Item
                label="Quality"
                name="quality"
                rules={[{ required: true, message: 'Please enter quality!' }]}
            >
                <Select
                    placeholder="Select Quality"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={defaults?.quality ?? []}
                />
            </Form.Item>
            <Form.Item
                label="Packing Per Box"
                name="packing_per_box"
                rules={[{ required: true, message: 'Please enter Packing Per Box!' }]}
            >
                <Input type="number" placeholder="Enter Packing Per Box" />
            </Form.Item>
            <Form.Item
                label="Quantiy"
                name="quantity"
                rules={[{ required: true, message: 'Please enter quantity!' }]}
            >
                <Input type="number" placeholder="Enter Quantity" />
            </Form.Item>
            <Form.Item
                label="Width (in mm)"
                name="width_in_mm"
            >
                <Input type="number" placeholder="Enter Width" disabled />
            </Form.Item>
            <Form.Item
                label="Height (in mm)"
                name="height_in_mm"
            >
                <Input type="number" placeholder="Enter Height" disabled />
            </Form.Item>
            <Form.Item
                label="Width (in inches)"
                name="width_in_inches"
            >
                <Input type="number" placeholder="Enter Width" disabled />
            </Form.Item>
            <Form.Item
                label="Height (in inches)"
                name="height_in_inches"
            >
                <Input type="number" placeholder="Enter Height" disabled />
            </Form.Item>
            {/* <Form.Item
                label="Coverage Per Piece (in sqft)"
                name="coverage_per_piece_in_sqft"
                rules={[{ required: true, message: 'Please enter Coverage Per Piece (in sqft)!' }]}
            >
                <Input type="number" placeholder="Enter Coverage Per Piece" />
            </Form.Item>
            <Form.Item
                label="Coverage (in sqft)"
                name="coverage_in_sqft"
                rules={[{ required: true, message: 'Please enter Coverage (in sqft)!' }]}
            >
                <Input type="number" placeholder="Enter Coverage" />
            </Form.Item>
            <Form.Item
                label="Product Description"
                name="description"
                rules={[{ required: true, message: 'Please enter product description!' }]}
            >
                <TextArea placeholder="Enter Product Description" allowClear />
            </Form.Item>
            <Form.Item
                label="Product Details"
                name="details"
                rules={[{ required: true, message: 'Please enter product details!' }]}
            >
                <TextArea placeholder="Enter Product Details" allowClear />
            </Form.Item> */}
            <Form.Item
                label="Upload Images (Max 10mb)"
                name="tile_images"
            >
                <Upload
                    accept="image/png, image/jpeg"
                    action={`${process.env.REACT_APP_API_URL}/images/upload/`}
                    name="image"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    beforeUpload={onDrop}
                >
                    + Upload
                </Upload>
            </Form.Item>
            <Form.Item>
                <button 
                    type="button" 
                    className="btn btn-reset font-2xl-regular"
                    onClick={resetForm}
                >
                    Reset
                </button>
                <button type="submit" className="btn btn-submit font-2xl-regular">
                    {productId ? 'UPDATE':'ADD'}
                </button>
            </Form.Item>
            <Form.Item>
                <button 
                    type="button" 
                    className="btn btn-delete font-2xl-regular"
                    onClick={deleteProduct}
                    style={{color: 'red'}}
                >
                    Delete
                </button>
            </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default AddProduct;