import React from 'react';
import { useParams } from 'react-router-dom';

import { Login } from './login';
import { Signup } from './signup';
import { Merchant } from './merchant';
import { ForgotPassword } from './forgotpassword';
import './account.scss';

const Account = () => {
  const { action } = useParams();

  return (
    <div className={`container-fluid login ${action}`}>
      <div className="account-container">
        {action === 'login' && <Login />}
        {action === 'forgotpassword' && <ForgotPassword />}
        {action === 'signup' && <Signup />}
        {action === 'details' && <Merchant />}
      </div>
    </div>
  );
};

export default Account;
