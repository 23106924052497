import React from 'react';
import { Carousel as AntCarousel } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.scss';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: 'black' }}
        onClick={onClick}
      />
    );
  }
  

const Carousel = ({images=[], thumbnail=true}) => {
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    var settings = {
        // slidesToShow: 4,
        // slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

      
    return (
        <div className="carousel">
            <div className="banner">
                <AntCarousel {...settings}>
                    { 
                        images.map((image, index) => (
                            <div key={index}>
                                <img src={image.image} alt="product" />
                            </div>
                        ))
                    }
                </AntCarousel>
            </div>
        </div>
    )
};

export default Carousel;