import { Avatar, List } from 'antd';
import { Link } from 'react-router-dom';
import VirtualList from 'rc-virtual-list';
import { useEffect, useState } from 'react';
import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import './staff-list.scss';


const StaffList = () => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const [data, setData] = useState([]);


    useEffect(() => {
        $http
            .get(`${process.env.REACT_APP_API_URL}/merchant/staffs/`)
            .then((res) => {
                console.log(res);
                setData(res);
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
    }, []);

    const getClass = (status) => {
        switch (status) {
            case true :
                return 'active';
            case false:
                return 'inactive';
            default: 
                return 'block';
        }
    }

    const getStatus = (status) => {
        switch (status) {
            case true :
                return 'Active';
            case false:
                return 'Inactive';
            default: 
                return 'Block';
        }
    }

  return (
    <div className='container staff-container'>
        <div className='page-Header mb-4 pb-4'>
            <span className='font-3xl-semibold'>Manage Staff</span>
            <span className='ml-4 pl-4'>
                <Link className="link" to={`/staffs/add`}>
                    <span className="ml-4 mr-3 font-3xl-regular">+</span>
                    <span>Add Staff Member</span>
                </Link>
            </span>
        </div>
        <List>
            <VirtualList
                data={data}
                itemHeight={47}
                itemKey="email"
            >
                {(item) => (
                <List.Item key={item.email}>
                    <div className={`staff-list row w-100 ${getClass(item.active)}`}>
                        <div className="col-md-3 val">
                            <Avatar src={item.image} />
                            <span className='ml-4'>{item.name ? item.name : 'NA'}</span>
                        </div>
                        <div className="col-md-2 text-center p-0 val">{item.email ? item.email : 'NA'}</div>
                        <div className="col-md-2 text-center val">{item.phone ? item.phone : 'NA'}</div>
                        <div className="col-md-2 text-center val">{item.permission ? item.permission : 'NA'}</div>
                        <div className="col-md-2 text-center p-0">
                            <span className={`pill ${getClass(item.active)}`}>{getStatus(item.active)}</span>
                        </div>
                        <div className="col-md-1 text-center">
                            <Link className="link" to={`/staffs/edit/${item.id}`}>Edit Staff</Link>
                        </div>
                    </div>
                </List.Item>
                )}
            </VirtualList>
            </List>
    </div>
  );
};
export default StaffList;