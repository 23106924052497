import { createSlice, createSelector } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loaderVisible: false,
    dateFilter: {
      key: 'timestamp',
      filter_type: 'range',
    },
    datePreset: null,
    link: {
      active: false,
      startPoint: null,
    },
    authInfo: {
      client_id: null,
      email: null,
      expiryTime: null,
      permissionValues: [],
      role: null,
      tokenStatus: null,
      userId: null,
      userStatus: null,
    },
    components: {
      callMoments: {
        loading: true,
        errored: false,
      },
      callDetails: {
        loading: true,
        errored: false,
      },
      callTranscripts: {
        loading: true,
        errored: false,
      },
      agentTable: {
        loading: true,
        errored: false,
      },
      agentSummary: {
        loading: true,
        errored: false,
      },
      agentFilters: {
        loading: true,
        errored: false,
      },
      workflowSummary: {
        loading: true,
        errored: false,
      },
      workflowRecentActivity: {
        loading: true,
        errored: false,
      },
      workflowCallQueue: {
        loading: true,
        errored: false,
      },
      workflowCallStats: {
        loading: true,
        errored: false,
      },
      workflowQueueSummary: {
        loading: true,
        errored: false,
      },
      workflowHistory: {
        loading: true,
        errored: false,
      },
      workflowFilters: {
        loading: true,
        errored: false,
      },
    },
    downloadList: [],
  },
  reducers: {
    showLoader: (state, action) => {
      state.loaderVisible = true;
    },
    hideLoader: (state, action) => {
      state.loaderVisible = false;
    },
    toggleLink: (state, action) => {
      if (action.payload && action.payload.active) {
        state.link = {
          active: true,
          startPoint: action.payload.startPoint,
        };
      } else {
        state.link = {
          active: false,
          startPoint: null,
        };
      }
    },
    updateDate: (state, action) => {
      state.dateFilter = action.payload.date;
      state.datePreset = action.payload.preset;
    },
    updateAuthInfo: (state, action) => {
      state.authInfo = action.payload;
    },
    setComponnentErrored: (state, action) => {
      action.payload.components.forEach((component) => {
        state.components[component].loading = false;
        state.components[component].errored = true;
      });
    },
    updateComponentLoading: (state, action) => {
      if (action.payload.loading) {
        state.components[action.payload.component].errored = false;
      } else {
        state.components[action.payload.component].errored =
          action.payload.loading;
      }
      state.components[action.payload.component].loading =
        action.payload.loading;
    },
    resetComponentStates: (state, action) => {
      if (action.payload.components) {
        action.payload.components.forEach((component) => {
          state.components[component].loading = true;
          state.components[component].errored = false;
        });
      }
    },
    addToDownloadList: (state, action) => {
      state.downloadList = [...state.downloadList, action.payload];
    },
    removeFromDownloadList: (state, action) => {
      state.downloadList = state.downloadList.filter(
        (ele) => ele.id !== action.payload.id
      );
    },
  },
  extraReducers: {},
});

export const {
  showLoader,
  hideLoader,
  toggleLink,
  updateDate,
  updateAuthInfo,
  setComponnentErrored,
  updateComponentLoading,
  resetComponentStates,
  addToDownloadList,
  removeFromDownloadList,
} = appSlice.actions;

export const loaderVisible = (state) => state.app.loaderVisible;
export const link = (state) => state.app.link;
export const authInfo = (state) => state.app.authInfo;
export const downloadList = (state) => state.app.downloadList;
export const components = (state) => state.app.components;
export const pageComponents = createSelector([components], (components) => {
  let componentsMap = {};
  for (const component in components) {
    if (Object.hasOwnProperty.call(components, component)) {
      componentsMap[component] = component;
    }
  }
  return componentsMap;
});
export const dateFilterSelector = (state) => state.app.dateFilter;
export const datePresetSelector = (state) => state.app.datePreset;

export default appSlice.reducer;
