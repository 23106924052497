import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { API } from '../../common/constants/api.constant';
import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import { authInfo } from '../../../app.reducer';
import './profile.scss';

const Profile = () => {
  const history = useHistory();
  const toastr = useToastr();
  const [profileData, setProfileData] = useState(null);
  const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
  const [editProfile, setEditProfile] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const { userId: USER_ID } = useSelector(authInfo);

  useEffect(() => {
    $http
      .get(`/user/${USER_ID}/profile`)
      .then((res) => {
        setProfileData(res);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const saveProfile = (values) => {
    const data = { ...profileData, ...values };
    $http
      .put(`/user/${USER_ID}/profile`, data)
      .then((res) => {
        const message = `Profile info saved successfully!.`;
        toastr.success(message);
        setEditProfile(false);
      })
      .catch((err) => {
        console.log(err);
        toastr.error('Something went wrong. Please try after sometime.');
      });
  };

  const onEditProfile = () => {
    setTimeout(() => {
      setEditProfile(true);
    }, 0);
  };

  const changePassword = (values) => {
    const data = { ...values };
    $http
      .put(`${API.PUT.CHANGE_PASSWORD}`, data)
      .then((res) => {
        const message = `Password changed successfully!`;
        toastr.success(message);
      })
      .catch((err) => {
        console.log(err);
        toastr.error('Something went wrong. Please try after sometime.');
      });
  };

  const redirectTo = (to) => {
    history.push(`/account/${to}`);
  };

  return (
    <div className="container-fluid profile">
      <div className="profile-container">
        <div className="page-header">
          <img
            src={`${process.env.REACT_APP_ASSET_DOMAIN}/call_analytics/assets/icons/profile-icon.svg`}
            alt="profile icon"
          />
          My Profile
        </div>

        {profileData ? (
          <div className="account-form text-left">
            <div className="row align-items-center h-100">
              <div className="col-sm-2 mx-auto">
                <span className="profile">
                  <img
                    src={`${process.env.REACT_APP_ASSET_DOMAIN}/call_analytics/assets/icons/profile-placeholder.svg`}
                    alt="profile icon"
                  />
                </span>
              </div>

              <div className="col-sm-6">
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={profileData}
                  onFinish={saveProfile}
                  onFinishFailed={onError}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <Form.Item
                        label="First and Last Name"
                        name="name"
                        rules={[
                          {
                            required: false,
                            message: 'Please enter your first and last name!',
                          },
                        ]}
                      >
                        <Input disabled={!editProfile} />
                      </Form.Item>
                    </div>
                    <div className="col-sm-6">
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: false,
                            message: 'Please enter your email!',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" disabled={true} />
                      </Form.Item>
                    </div>
                    {/* <div className="col-sm-6">
                      <Form.Item
                        label="Designation"
                        name="designation"
                        rules={[
                          {
                            required: false,
                            message: 'Please enter your designation!',
                          },
                        ]}
                      >
                        <Input disabled={!editProfile} />
                      </Form.Item>
                    </div> */}
                    <div className="col-sm-6">
                      <Form.Item
                        label="Mobile"
                        name="mobile"
                        rules={[
                          {
                            required: false,
                            message: 'Please enter your mobile number!',
                          },
                        ]}
                      >
                        <Input disabled={!editProfile} />
                      </Form.Item>
                    </div>
                    <div className="col-sm-12">
                      <Form.Item>
                        {editProfile ? (
                          <>
                            <button
                              type="submit"
                              className="btn btn-submit primary-btn"
                            >
                              Save Changes
                            </button>
                            <button
                              type="button"
                              className="btn secondary-btn"
                              onClick={() => setEditProfile(false)}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-submit primary-btn"
                            onClick={() => onEditProfile()}
                          >
                            Edit Profile
                          </button>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="col-sm-1 text-center">
                <div className="separator"></div>
              </div>
              <div className="col-sm-3">
                {editPassword ? (
                  <Form
                    name="basic"
                    layout="vertical"
                    onFinish={changePassword}
                    onFinishFailed={onError}
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <Form.Item
                          label="Current Password"
                          name="currentPassword"
                          rules={[
                            {
                              min: 8,
                              message:
                                'Use 8 or more characters with at least 1 Number and 1 Symbol',
                            },
                            {
                              required: true,
                              message: 'Please enter your password!',
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12">
                        <Form.Item
                          label="New Password"
                          name="newPassword"
                          rules={[
                            {
                              min: 8,
                              message:
                                'Use 8 or more characters with at least 1 Number and 1 Symbol',
                            },
                            {
                              required: true,
                              message: 'Please enter your password!',
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12">
                        <Form.Item>
                          <button
                            type="submit"
                            className="btn btn-submit primary-btn"
                          >
                            Update Password
                          </button>
                          <button
                            type="button"
                            className="btn secondary-btn"
                            onClick={() => setEditPassword(false)}
                          >
                            Cancel
                          </button>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{ password: '**********' }}
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <Form.Item
                          label="Current Password"
                          name="password"
                          rules={[
                            {
                              min: 8,
                              message:
                                'Use 8 or more characters with at least 1 Number and 1 Symbol',
                            },
                            {
                              required: true,
                              message: 'Please enter your password!',
                            },
                          ]}
                        >
                          <Input disabled={true} />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12">
                        <Form.Item>
                          <button
                            type="submit"
                            className="btn btn-submit primary-btn"
                            onClick={() => setEditPassword(true)}
                          >
                            Change Password
                          </button>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Profile;
