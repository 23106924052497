import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Input, Select, Upload } from "antd";
import { useHistory, Link, useParams } from "react-router-dom";
import { AuthContext } from "../../common/context/auth.context";
import { API } from "../../common/constants/api.constant";

import { IonIcon } from "@ionic/react";
import { arrowBackCircleOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";

import useHTTP from "../../common/hooks/http";
import useToastr from "../../common/hooks/toastr";
import { showLoader, hideLoader } from "../../../app.reducer";
import { authInfo } from "../../../app.reducer";
import "./profile.scss";

const { TextArea } = Input;
const Option = Select.Option;
var profileRequest;

const ViewProfile = () => {
  const toastr = useToastr();
  const [form] = Form.useForm();
  const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [defaults, setDefaults] = useState({});
  const dispatch = useDispatch();
  //const formRef = useRef(null);
  //const userid =  useSelector(authInfo);
  let ID = authContext?.userDetail?.merchant?.id;
  console.log("check-idd", ID);
  useEffect(() => {
    fetchProfile();
  }, [ID]);

  const fetchProfile = () => {
    $http
      .get(`${process.env.REACT_APP_API_URL}/merchants/${ID}/`)
      .then((res) => {
        profileRequest = res;
        const values = {
          name: res.name,
          phone_number: res.phone_number,
          website: res.website,
          gst_number: res.gst_number,
          pan: res.pan,
          address1: res.address1,
          address2: res.address2,
          postal_code: res.postal_code,
          country: res.country.name,
          region: res.region.name,
          city: res.city.name,
          owner: res.owner,
        };
        form.setFieldsValue(values);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
      });
  };

  const onError = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const updateProfile = (values) => {
    if (values.owner) {
      delete values.owner;
    }

    if (values.country == profileRequest.country.name) {
      values.country = profileRequest.country.id;
    }
    if (values.region == profileRequest.region.name) {
      values.region = profileRequest.region.id;
    }
    if (values.city == profileRequest.city.name) {
      values.city = profileRequest.city.id;
    }
    const data = {
      ...values,
    };
    dispatch(showLoader());
    $http
      .patch(`${process.env.REACT_APP_API_URL}/merchants/${ID}/`, data)
      .then((res) => {
        const message = `Profile updated successfully!`;
        toastr.info(message);
        dispatch(hideLoader());
        history.push(`/`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
        dispatch(hideLoader());
      });
  };

  const goBack = () => {
    history.goBack();
  };

  const getLocation = (url, key) => {
    return new Promise((resolve, reject) => {
      $http
        .get(`${url}`)
        .then((res) => {
          const results = res.map((ele) => ({
            label: key ? ele[key] : ele.name,
            value: ele.id,
          }));
          resolve(results);
        })
        .catch((err) => {
          console.log(err);
          toastr.error(err.data.message);
        });
    });
  };

  useEffect(() => {
    getLocation(API.GET.COUNTRIES).then((res) => setCountries(res));
  }, []);

  const onCountryChange = (value) => {
    getLocation(`${API.GET.REGIONS}?country_id=${value}`).then((res) => {
      setRegions(res);
      setCities([]);
    });
  };

  const onRegionChange = (value) => {
    getLocation(`${API.GET.CITIES}?region_id=${value}`, "name_ascii").then(
      (res) => {
        setCities(res);
      }
    );
  };

  return (
    <>
      <div className="product-form">
        <div className="row">
          <div className="col-sm-12 col-xs-12 back-btn">
            <button className="link" onClick={goBack}>
              <span className="icon">
                <IonIcon icon={arrowBackCircleOutline}></IonIcon>
              </span>{" "}
              Back
            </button>
          </div>
        </div>
        {authContext?.userDetail?.permission === "Admin" ? (
          <>
            <div className="row my-4 px-0">
              <div className="col-sm-12 col-xs-12 font-3xl-semibold">
                Merchant Profile
              </div>
            </div>

            <Form
              //ref={formRef}
              name="basic"
              layout="vertical"
              form={form}
              onFinish={updateProfile}
              onFinishFailed={onError}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter name!" }]}
              >
                <Input placeholder="Name" />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phone_number"
                // rules={[
                //     { pattern: /^\d{10}$/, message: 'Please enter a valid phone number', },
                // ]}
                rules={[{required:true,message: 'Please enter a valid phone number',}]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Website"
                name="website"
                rules={[
                  {
                    type: "url",
                    //required: true,
                    message: "Please enter website!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="GST Number"
                name="gst_number"
                rules={[{ required: false, message: "Please enter gst!" }]}
              >
                <Input placeholder="Enter gst number" />
              </Form.Item>
              <Form.Item
                label="PAN Number"
                name="pan"
                rules={[{ required: false, message: "Please enter pan!" }]}
              >
                <Input placeholder="Enter pan number" />
              </Form.Item>

              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Select your country!" }]}
              >
                <Select
                  options={countries}
                  showSearch={true}
                  optionFilterProp="label"
                  onChange={onCountryChange}
                  allowClear
                ></Select>
              </Form.Item>

              <Form.Item
                label="Region"
                name="region"
                rules={[{ required: true, message: "Select your state!" }]}
              >
                <Select
                  options={regions}
                  showSearch={true}
                  optionFilterProp="label"
                  onChange={onRegionChange}
                  allowClear
                ></Select>
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Select your city!" }]}
              >
                <Select
                  options={cities}
                  showSearch={true}
                  optionFilterProp="label"
                  onChange={onRegionChange}
                  allowClear
                ></Select>
              </Form.Item>

              <Form.Item label="Owner" name="owner">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="PIN CODE"
                name="postal_code"
                rules={[
                  { required: true, message: "Please enter your pin code!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Address 1" name="address1">
                <Input.TextArea allowClear />
              </Form.Item>

              <Form.Item label="Address 2" name="address2">
                <Input.TextArea allowClear />
                {/* <TextArea placeholder="Enter Product Description" allowClear /> */}
              </Form.Item>

              <Form.Item>
                <button
                  type="submit"
                  className="btn btn-submit font-2xl-regular"
                  //onClick={updateProfile}
                >
                  Update
                </button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div
            className="col-sm-12 col-xs-12 font-3xl-semibold"
            style={{ marginTop: 20 }}
          >
            <center>Admin permission does not found!</center>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewProfile;
