import { toast } from 'react-toastify';

export const TOASTR = {
  autoClose: 3000,
  draggable: false,
  position: toast.POSITION.TOP_RIGHT,
  newestOnTop: true,
  hideProgressBar: true,
  pauseOnHover: true,
};

export const SNACKBAR = {
  autoClose: 5000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER,
  newestOnTop: false,
  hideProgressBar: true,
  pauseOnHover: true,
  limit: 3,
  className: 'snackbar',
};

export const formSavingState = Object.freeze({
  NOT_SAVED: 1,
  SAVING: 2,
  SAVED: 3,
  SAVE_ERRORED: 4,
});

export const HIGHCHART_TYPES = ['donut', 'pie', 'column', 'line', 'bar'];
