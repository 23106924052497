import { useEffect } from 'react';

import { toast, toast as snackbar } from 'react-toastify';
import { TOASTR, SNACKBAR } from '../constants/app.constant';

const useToastr = () => {
  useEffect(() => {
    toast.configure(TOASTR);
    snackbar.configure(SNACKBAR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getConfig(option = {}) {
    return JSON.parse(JSON.stringify(option));
  }
  function error(message, option) {
    toast.error(message, option);
  }

  function success(message, option) {
    toast.success(message, getConfig(option));
  }

  function warning(message, option) {
    toast.warn(message, getConfig(option));
  }

  function info(message, option) {
    toast.info(message, getConfig(option));
  }

  function snackbarSuccess(message, option) {
    if (toast.isActive('snackbar')) {
      toast.dismiss('snackbar');
    }
    snackbar.success(message, getConfig({ ...option, toastId: 'snackbar' }));
  }
  function snackbarError(message, option) {
    snackbar.error(message, getConfig(option));
  }

  return {
    info: info,
    error: error,
    success: success,
    warning: warning,
    snackbarSuccess: snackbarSuccess,
    snackbarError: snackbarError,
  };
};

export default useToastr;
