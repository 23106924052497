import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input } from 'antd';

import { API } from '../../common/constants/api.constant';
import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import './forgotpassword.scss';
import logo from "../../../assets/logo.jpeg";
export const ForgotPassword = () => {
  const [type, setType] = useState('email');
  const [otpSent, setOtpSent] = useState(false);
  const history = useHistory();
  const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
  const toastr = useToastr();
  const [form] = Form.useForm();

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const sendEmail = () => {
    const data = { 
      ...form.getFieldsValue(),
      type,
    };
    
    $http
      .post(`${API.POST.SEND_OTP}`, data)
      .then((res) => {
        if (otpSent) {
          setOtpSent(false);
          history.push('/account/login');
          const message = `Password changed successfully.`;
          toastr.info(message);
        } else {
          const message = `An OTP has been sent to ${data.email}.`;
          toastr.info(message);
          setOtpSent(true);
        }
        // history.push('/account/login');
        // const message = `An Email has been sent to ${values.email}. Please click on the link in the email to reset your account password.`;
        // toastr.info(message);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.message);
      });
  };

  const isFormInvalid = errors => {
    const items = errors.filter(err => err.errors?.length > 0 || err.warnings?.length > 0)
    return items.length > 0;
  }

  const forgotpassword = () => {
    const isInvalid = isFormInvalid(form.getFieldsError());
    if (isInvalid) {
      return;
    }
    const data = {...form.getFieldsValue(),type};
    console.log('check-type',data)
    $http
      .post(`${API.POST.FORGOT_PASSWORD}`, {
        ...data,
      })
      .then((res) => {
        redirectTo('login');
      })
      .catch((err) => {
        if (err && err.data && err.data.message && !isInvalid) {
          toastr.error(err.data.message);
        }
      });
  };

  const redirectTo = (to) => {
    history.push(`/account/${to}`);
  };

  return (
    <div className="account-form text-center">
      {/* <div className="brand-title">
        <div className="circle"></div>
        <div>Signature Surfaces</div>
      </div> */}
      <div className="brand-title">
        <div className="brand-name">
          <img
            className="navigation__logo"
            style={{ marginRight: 50, height: "90px", width: "180px" }}
            src={logo}
            alt="logo"
          />
        </div>
        {/* <div>Signature Surfaces</div> */}
      </div>
      <div className="row m-4">
        <div className="col-sm-12 col-xs-12 text-center font-3xl-semibold">Forgot Password</div>
        {/* <div className="col-sm-12 col-xs-12 text-center font-lg-regular subtitle">Enter your email below</div> */}
      </div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinishFailed={onError}
      >
        <Form.Item
          label="Please provide us the registered email-id"
          name="email"
          rules={[
            { type: 'email', message: 'The input is not valid E-mail!' },
            { required: true, message: 'Please enter your email!' },
          ]}
          hidden={otpSent}
        >
          <Input />
        </Form.Item>
        {
          !otpSent && <>
          <div className="row mb-5">
            <div className="col-md-12 text-left help-text">
              An otp will be shared on the registered email-id
            </div>
          </div>

          </>
          
        }
        {
          otpSent && <>
          <Form.Item
            label="OTP"
            name="otp"
            placeholder="Enter 6 digit OTP"
            rules={[
              { required: true, message: 'Please enter 6 digit OTP!' },
              { pattern: /^\d{6}$/, message: 'Enter 6 digit OTP' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              label="Password"
              name="new_password1"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="new_password2"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password />
            </Form.Item>
          </>
        }
        {otpSent ?
          <Form.Item className="text-center">
            <button 
              type="submit" 
              className="btn btn-submit btn-login font-2xl-regular"
              onClick={forgotpassword}>
              Reset Password
            </button>
          </Form.Item>
          :
          <Form.Item className="text-center">
            <button 
              type="submit" 
              className="btn btn-submit btn-login font-2xl-regular"
              onClick={sendEmail}>
              Request Password Reset
            </button>
          </Form.Item>
        }
        <Form.Item className="text-left">
          <span className="subtitle">Do you know your password?</span> 
          <button
            className="btn btn-link p-0 ml-2 font-2xl-regular"
            onClick={() => redirectTo('login')}
          >
            Log in
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
