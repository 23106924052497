import { useEffect, useRef } from 'react';
import axios from 'axios';

import Utils from '../services/utils.service';

const useHTTP = (endPoint) => {
  const instance = useRef();
  const api = axios.create({
    baseURL: endPoint || process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' },
    // withCredentials: true
  });
  useEffect(() => {
    instance.current = api;
    // axios interceptors
    instance.current.interceptors.request.use(
      function (config) {
        const { access } = Utils.readCookie(
          process.env.REACT_APP_ACCOUNT_COOKIE
        );
        if (access != null) {
          config.headers.Authorization = `Bearer ${access}`;
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    instance.current.interceptors.response.use(null, function (error) {
      return Promise.reject(error);
    });
    // instance.current.interceptors.response.use(null, function (error) {
    //     return Promise.reject(error);
    // });
  }, [api]);

  /**
   * @param {string} path
   * @param {Object} options
   * @param {Object} options.pathParams
   * @param {Array.<{key: string, value: string|number}>} options.queryParams
   */
  const get = (path, options = {}) => {
    let url = Utils.getRequestPath(
      path,
      options.pathParams,
      options.queryParams
    );
    return new Promise((resolve, reject) => {
      instance.current
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  };

  const prepareRequest = (path, body, formData, options) => {
    let url = Utils.getRequestPath(
      path,
      options.pathParams,
      options.queryParams
    );
    let requestBody;
    if (formData) {
      requestBody = Utils.createFormData(formData);
      options.headers = {
        'Content-Type': 'multipart/form-data',
      };
    } else {
      requestBody = body;
    }
    return {
      url,
      requestBody,
    };
  };

  /**
   * @param {string} path
   * @param {any} [body]
   * @param {Array.<{key: string, value: string|number|boolean, binary?:boolean}>} [formData]
   * @param {Object} options
   * @param {Object} options.pathParams
   * @param {Array.<{key: string, value: string|number}>} options.queryParams
   * @param {Object} options.headers key value pair of headers
   */
  const post = (path, body, formData, options = {}) => {
    return new Promise((resolve, reject) => {
      let { url, requestBody } = prepareRequest(path, body, formData, options);
      instance.current
        .post(
          url,
          requestBody,
          { cancelToken: options.cancelToken },
          {
            headers: options.headers,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  };

  /**
   * @param {string} path
   * @param {any} [body]
   * @param {Array.<{key: string, value: string|number|boolean, binary?:boolean}>} [formData]
   * @param {Object} options
   * @param {Object} options.pathParams
   * @param {Array.<{key: string, value: string|number}>} options.queryParams
   * @param {Object} options.headers key value pair of headers
   */
  const put = (path, body, formData, options = {}) => {
    return new Promise((resolve, reject) => {
      let { url, requestBody } = prepareRequest(path, body, formData, options);
      instance.current
        .put(url, requestBody, {
          headers: options.headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  };

  /**
   * @param {string} path
   * @param {any} [body]
   * @param {Array.<{key: string, value: string|number|boolean, binary?:boolean}>} [formData]
   * @param {Object} options
   * @param {Object} options.pathParams
   * @param {Array.<{key: string, value: string|number}>} options.queryParams
   * @param {Object} options.headers key value pair of headers
   */
   const patch = (path, body, formData, options = {}) => {
    return new Promise((resolve, reject) => {
      let { url, requestBody } = prepareRequest(path, body, formData, options);
      instance.current
        .patch(url, requestBody, {
          headers: options.headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  };

  /**
   * @param {string} path
   * @param {any} [body]
   * @param {Array.<{key: string, value: string|number|boolean, binary?:boolean}>} [formData]
   * @param {Object} options
   * @param {Object} options.pathParams
   * @param {Array.<{key: string, value: string|number}>} options.queryParams
   * @param {Object} options.headers key value pair of headers
   */
  const deleteReq = (path, body, formData, options = {}) => {
    return new Promise((resolve, reject) => {
      let { url, requestBody } = prepareRequest(path, body, formData, options);
      instance.current
        .delete(url, requestBody, {
          headers: options.headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  };

  return {
    get,
    post,
    put,
    patch,
    deleteReq,
  };
};

export default useHTTP;
