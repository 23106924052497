import React, { useState, useEffect, useContext, useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { arrowForwardCircleOutline, createOutline, closeCircleOutline } from 'ionicons/icons';
import { InputNumber, Modal, Popconfirm } from 'antd';

import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import { AuthContext } from '../../common/context/auth.context';
import './product.scss';
import '../../home/home.scss'

const Products = ({productList}) => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const {type } = useParams();
    const { userDetail } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [action, setAction] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [products, setProducts] = useState([]);


    useEffect(() => {
        setProducts(productList);
    }, [productList]);

    const updateQuantity = (productId, qty) => {
        $http
        .patch(`${process.env.REACT_APP_API_URL}/${type}/products/${productId}/`, {quantity: qty})
        .then((res) => {
            let data = [...products];
            data = data.map(ele => {
                if (ele.id === productId) {
                    return {
                        ...ele,
                        quantity: res.quantity
                    }
                }
                return ele;
            });
            setProducts(data);
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
        });
    }

    const showModal = (product, action) => {
        setAction({
            ...product,
            action,
        });
        setIsModalOpen(true);
    };
    
      const handleOk = () => {
        if (!isNaN(quantity) && quantity > 0) {
            console.log(quantity);
            console.log(action.quantity);
            let qty = parseInt(action.quantity);
            switch(action.action) {
                case '-':
                    qty -= quantity;
                    break;
                case '+':
                    qty += quantity;
                    break;
                default:
                    console.log('action not defined');
            }
            console.log(qty);
            if (qty!== 0 || quantity !== qty) {
                updateQuantity(action.id, qty);
            }
            setQuantity(0);
            setIsModalOpen(false);
        } else {
            toastr.error('Please enter a valid quantity');
        }
      };

    const deleteProduct = (id) => {
        $http
            .deleteReq(`${process.env.REACT_APP_API_URL}/${type}/products/${id}/`, {})
            .then((res) => {
                const message = `Product deleted successfully!`;
                toastr.info(message);
                setProducts(products.filter(c => c.id !== id));
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
    }

      const handleCancel = () => {
        setQuantity(0);
        setIsModalOpen(false);
      };


    return (
            <>
                {
                    products.map(product => (
                        <div className="col-xs-12 col-sm-4 my-4" key={product.id}>
                            <div className="card">
                                <div className="row">
                                    <div className="col-6 col-sm-6 pr-0 image">
                                        {
                                            product.tile_images?.[0]?.image ? 
                                            <img src={product.tile_images?.[0]?.image} alt="product" />
                                            : null
                                        }
                                        
                                    </div>
                                    <div className="col-6 col-sm-6 pl-4">
                                        <div className="card__body">
                                            {
                                                userDetail?.permission !== 'Viewer' && 
                                                <span className="action">
                                                    <Link to={`/${type}/${product.category}/product/edit/${product.id}`}>
                                                        <IonIcon icon={createOutline} />
                                                    </Link>
                                                    <Popconfirm
                                                        title="Are you sure to delete this product?"
                                                        onConfirm={() => deleteProduct(product.id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <a href="#">
                                                            <IonIcon name='Delete Category' icon={closeCircleOutline} />
                                                        </a>
                                                    </Popconfirm>
                                                </span>
                                            }
                                            <div className="card__body__header font-xl-bold uppercase">
                                                {product.name}
                                            </div>
                                            <div className="card__body__header font-xl-regular">Pack of {product.packing_per_box}</div>
                                            <div className="card__body__header font-xl-regular uppercase">{product.brand}</div>
                                            <div className="card__body__header font-xl-regular">
                                                Quantity: 
                                                {
                                                    userDetail?.permission === 'Viewer' ?
                                                    <span>{product.quantity}</span>
                                                    : 
                                                    <InputNumber 
                                                        addonBefore={<button onClick={() => showModal(product, '-')} disabled={userDetail?.permission === 'Viewer'}>-</button>} 
                                                        addonAfter={<button onClick={() => showModal(product, '+')} disabled={userDetail?.permission === 'Viewer'}>+</button>}
                                                        value={product.quantity} 
                                                        disabled={true}
                                                    />
                                                }
                                            </div>
                                            <Link to={`/${type}/${product.category}/products/${product.id}`}>
                                                <div className="card__link text-right mt-4">
                                                    View Product 
                                                    <span className="icon"><IonIcon icon={arrowForwardCircleOutline}></IonIcon></span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    action?.action && 
                    <Modal  
                        destroyOnClose={true}
                        width={200} 
                        closable={false} 
                        visible={isModalOpen} 
                        okText={action.action === '+' ? 'Add' : 'Sell'}
                        onOk={handleOk} 
                        onCancel={handleCancel}>
                        <InputNumber 
                            inputMode="numeric"
                            className="quantity" 
                            value={quantity || null}
                            onChange={(value) => setQuantity(value)}
                            autoFocus
                        />
                    </Modal>
                }
            </>
    )
}

export default Products;