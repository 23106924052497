import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Radio } from 'antd';
import { useDispatch } from 'react-redux';

import { API } from '../../common/constants/api.constant';
import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import Utils from '../../common/services/utils.service';
import { updateAuthInfo } from '../../../app.reducer';
import './signup.scss';
import logo from '../../../assets/logo.jpeg';

export const Signup = () => {
  const [type, setType] = useState('email');
  const [otpSent, setOtpSent] = useState(false);
  const [signupData, setSignupData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const toastr = useToastr();
  const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function navigateTo() {
    let params = new URLSearchParams(window.location.search);
    let returnUrl = params.get("returnUrl");
    window.open(
      returnUrl || window.location.origin + process.env.PUBLIC_URL,
      "_self"
    );
  }
  const signup = (values) => {
    if (values.phone) {
      values.phone = `+91${values.phone}`;
    }
    const data = { 
      ...values,
      type, 
    };
    $http
      .post(`${API.POST.SIGNUP}`, data)
      .then((res) => {
        // history.push('/account/login');
        const message = `An OTP has been sent to your ${type} - ${values.email || values.phone}`;
        toastr.info(message);
        setSignupData(data);
        setOtpSent(true);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
      });
  };

  const verifyOtp = (values) => {
    if (values.phone) {
      values.phone = `+91${values.phone}`;
    }
    const data = { 
      ...values,
      type, 
    };    
    if (type === 'phone') {
      data.phone = signupData.phone;
    } else {
      data.email = signupData.email;
    }
    $http
      .post(`${API.POST.VERIFY_OTP}`, data)
      .then((res) => {
        console.log('check-res',data);    
        dispatch(
          updateAuthInfo({
            ...res
          })
        );
        setCookie(res);
        console.log('check-data',res)
        if(res.merchant_id == null){
        window.location.href = '/account/details';
        } else{
          // window.location.href = '/';
          navigateTo();
        }
        // const message = `OTP verified successfully.`;
        // toastr.info(message);
        //setSignupData(data);
        setOtpSent(true);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
      });
  };

  const setCookie = (data) => {
    let d = new Date();
    d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
    Utils.setCookie('ss_user', JSON.stringify(data), d);
  };

  const redirectTo = (to) => {
    history.push(`/account/${to}`);
  };

  return (
    <>
      <div className="account-form text-center">
        <div className="brand-title">
        <div className="brand-name">
        <img 
          className='navigation__logo'
          style={{marginRight:50,height:'90px',width:'180px'}}
          src={logo}
          alt="logo"
        />
        </div>
          {/* <div>Signature Surfaces</div> */}
        </div>
        <div className="row my-4 px-0">
          <div className="col-sm-12 col-xs-12 text-center font-3xl-semibold px-0">Sign up to Dashboard</div>
          <div className="col-sm-12 col-xs-12 text-center font-lg-regular subtitle">Enter your details below</div>
        </div>
        {
          !otpSent ?
            <Form
              name="basic"
              layout="vertical"
              onFinish={signup}
              onFinishFailed={onError}
            >
              <Form.Item>
                <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
                  <Radio value="email"> Email </Radio>
                  <Radio value="phone"> Mobile </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter your name!' }]}
              >
                <Input />
              </Form.Item>
              {
                type === 'email' && <>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { type: 'email', message: 'The input is not valid E-mail!' },
                      { required: true, message: 'Please enter your email!' },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        min: 5,
                        message:
                          'Use 5 or more characters with at least 1 Number and 1 Symbol',
                      },
                      { required: true, message: 'Please enter your password!' },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              }
              {
                type === 'phone' && <>
                  <Form.Item
                    label="Phone Number"
                    name="phone"
                    placeholder="Enter phone number"
                    rules={[
                      { required: true, message: 'Please enter your phone number!' },
                      { pattern: /^\d{10}$/, message: 'Please enter a valid phone number' },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        min: 5,
                        message:
                          'Use 5 or more characters with at least 1 Number and 1 Symbol',
                      },
                      { required: true, message: 'Please enter your password!' },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  </>
              }
              
              <Form.Item>
                <button type="submit" className="btn btn-submit btn-login font-2xl-regular">
                  Create Account
                </button>
              </Form.Item>
              <Form.Item className="text-left">
                <span className="subtitle">Already have an account?</span> 
                <button
                  className="btn btn-link p-0 ml-2 font-2xl-regular"
                  onClick={() => redirectTo('login')}
                >
                  Log in
                </button>
              </Form.Item>
            </Form>
          :
          <Form
            name="basic"
            layout="vertical"
            onFinish={verifyOtp}
            onFinishFailed={onError}
          >
            <Form.Item
              label="OTP"
              name="otp"
              placeholder="Enter 6 digit OTP"
              rules={[
                { required: true, message: 'Please enter 6 digit OTP!' },
                { pattern: /^\d{6}$/, message: 'Enter 6 digit OTP' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <button type="submit" className="btn btn-submit btn-login font-2xl-regular">
                Submit
              </button>
            </Form.Item>
          </Form>

        }
        
      </div>
    </>
  );
};
