import React, { useState, useEffect, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { createOutline, arrowBackCircleOutline, arrowForwardCircleOutline, closeCircleOutline, shareSocial } from 'ionicons/icons';
import { Form, Modal, Select, Tag, Popconfirm, Spin } from 'antd';
import moment from 'moment';

import { API } from '../common/constants/api.constant';
import useHTTP from '../common/hooks/http';
import useToastr from '../common/hooks/toastr';
import { AuthContext } from '../common/context/auth.context';
import Carousel from '../common/components/carousel';
import { useDebouncedEffect } from '../common/hooks/useDebouncedEffect';
import './productDetail.scss';

const { Option } = Select;


const ProductDetail = () => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const {type, category, productId } = useParams();
    const { userDetail } = useContext(AuthContext);
    const history = useHistory();
    const [productDetail, setProductDetail] = useState(null);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [productList, setProductList] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [textInput, setTextInput] = useState(null);
    const [linkProductLoading, setLinkProductLoading] = useState(false);
    const [log, setLog] = useState(null);

    useEffect(() => {
        $http
            .get(`${process.env.REACT_APP_API_URL}/${type}/products/${productId}/`)
            .then((res) => {
                setProductDetail(res);
                fetchDefaultOptions({...res});
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
    }, [productId, type, category]);

    const fetchDefaultOptions = (product) => {
        $http
          .get(`${process.env.REACT_APP_API_URL}/${type}/defaults/?category_id=${category}`)
          .then((res) => {
            let data = res.application_type.find(ele => ele.id === product.application_type);
            product.application_type = data?.type;
            data = res.body_type.find(ele => ele.id === product.body_type);
            product.body_type = data?.type;
            product.make_type = res.make_type?.type ?? 'NA';
            data = res.surface_type.find(ele => ele.id === product.surface_type);
            product.surface_type = data?.type;
            data = res.quality.find(ele => ele.id === product.quality);
            product.quality = data?.type;
            setProductDetail({...product});
          })
          .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
        });
    }

    const handleTextChange = (val) => {
        setLinkProductLoading(true);
        setTextInput(val);
    };
    
    useDebouncedEffect(
    () => {
        getLinkedProducts(textInput);
    },
    1000,
    [textInput]
    );

    const getLinkedProducts = (search) => {
        const exclude_ids = [productId];
        productDetail?.linked_products?.forEach(prod => {
            exclude_ids.push(prod.linked_product);
        })
        let url = `${process.env.REACT_APP_API_URL}/${type}/products/?exclude_ids=${exclude_ids.join(',')}`
        if (search) {
            url += `&search=${search}`;
        }
        
        $http
            .get(url)
            .then((res) => {
                setProductList(res?.results ?? []);
                setProductOptions(res?.results ?? []);
                setLinkProductLoading(false);
                // setTextInput(null);
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
    }

    const unlinkProduct = (id) => {
        const link_prods = productDetail?.linked_products?.filter(prod => prod.linked_product !== id).map(prod => {
            return {
                linked_product: prod.id
            }
        })
        const data = {
            linked_products: link_prods,
        }
        $http
            .patch(`${process.env.REACT_APP_API_URL}/${type}/products/${productDetail.id}/`, data)
            .then((res) => {
                setProductDetail(res);
                const message = `Product unlinked successfully!`;
                toastr.info(message);
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
    }

    const openLinkModal = () => {
        getLinkedProducts();
        setShowLinkModal(true)
    }

    const linkProducts = () => {
        if (selectedProducts.length > 0) {
            const products = selectedProducts.map(id => {
                return {
                    linked_product: id
                }
            });
            const link_prods = productDetail?.linked_products?.map(prod => {
                return {
                    linked_product: prod.id
                }
            })
            const data = {
                linked_products: [...link_prods, ...products],
            }
            $http
            .patch(`${process.env.REACT_APP_API_URL}/${type}/products/${productDetail.id}/`, data)
            .then((res) => {
                setSelectedProducts([]);
                const message = `Product linked successfully!`;
                toastr.info(message);
                setShowLinkModal(false);
                setProductDetail(res);
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
        } else {
            toastr.info("Select at lease one product to link");
        }
    }

    const goBack = () => {
        history.goBack();
    }


    const message = `
    ${productDetail?.tile_images?.[0]?.image}
    Tiles Name: ${productDetail?.name}
    Size: ${Number(productDetail?.height_in_inches)}x${Number(productDetail?.width_in_inches)} Inches
    Quality: ${productDetail?.quality}
    Brand Name: ${productDetail?.brand}
    Packing: Pack of ${productDetail?.packing_per_box}
    `;

    console.log(productDetail?.tile_images?.[0]?.image);

    const encodeMessage = encodeURIComponent(message);

    // WhatsApp share URL
    const whatsappMobUrl = `whatsapp://send?text=${encodeMessage}`;
    const whatsappUrl = `https://wa.me/?text=${encodeMessage}`;

    return (
        <div className="product-container container">
            <meta property="og:title" content={productDetail?.name} />
            <meta property="og:description" content={`${Number(productDetail?.height_in_inches)}x${Number(productDetail?.width_in_inches)} Inches`} />
            <meta property="og:image" content={productDetail?.tile_images?.[0]?.image} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <div className="row mb-4">
                <div className="col-3 col-sm-1 back-btn">
                <button className="link" onClick={goBack}><span className="icon"><IonIcon icon={arrowBackCircleOutline}></IonIcon></span> Back</button>
                </div>
                <div className="col-9 col-sm-10 title-header">
                    {/* <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item href=""><Link to={`/${category}/categories`}>Categories</Link></Breadcrumb.Item>
                        <Breadcrumb.Item href=""><Link to={`/${category}/products`}>Products</Link></Breadcrumb.Item>
                        <Breadcrumb.Item href=""><Link>{productDetail?.name}</Link></Breadcrumb.Item>
                    </Breadcrumb> */}
                    <span className="font-2xl-bold value">{productDetail?.name}</span>
                    <span className="modified">Modified On - {moment(productDetail?.updated_at).format('YYYY-MM-DD hh:mm A')}</span>
                </div>
            </div>
            {
                productDetail && 
                <div className="row product-detail">
                    <div className="col-xs-12 col-sm-4 mb-4 pb-4">
                        <Carousel images={productDetail.tile_images} />
                        <div className="text-center mt-4 pt-4 d-block d-sm-none">
                            <span className="action ml-4">
                                <a className="link" href={whatsappMobUrl} target="_blank" rel="noopener noreferrer" style={{display: 'flex', 'justifyContent': 'center', alignItems: 'center'}}>
                                    <IonIcon size="large" icon={shareSocial} /> 
                                    <span style={{marginLeft: '5px', fontWeight: 'bold'}}>Share on Whatsapp</span>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-8">
                        <div className="details">
                            <div className="details__header">
                                <span className="font-2xl-bold value">{productDetail.name}</span>
                                <span className="quantity font-xl-semibold">Quantity - {productDetail.quantity} Boxes</span>
                                {
                                    userDetail?.permission !== 'Viewer' && 
                                    <>
                                        <span className="action ml-4">
                                            <a className="link" onClick={openLinkModal}>
                                                <IonIcon icon={createOutline} /> Link Products
                                            </a>
                                        </span>
                                        <span className="action edit">
                                            <Link to={`/${type}/${category}/product/edit/${productDetail.id}`}>
                                                <IonIcon icon={createOutline} /> Edit Product
                                            </Link>
                                        </span>
                                    </>
                                }
                            </div>
                            <div className="details__size font-xl-regular">{Number(productDetail.height_in_inches)}x{Number(productDetail.width_in_inches)} Inches</div>
                            <div className="font-xl-semibold mt-4">Packing</div>
                            <div className="font-lg-regular details__variant mb-4">Pack of {productDetail.packing_per_box}</div>
                            { 
                                productDetail.description && <>
                                    <div className="font-xl-bold mt-4">Product Description</div>
                                    <div className="font-lg-regular value">{productDetail.description}</div>
                                </>
                            }
                            <div className="font-2xl-bold mt-4">Product Details</div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-4">
                                    <span className="font-xl-regular">Brand: </span>
                                    <span className="font-xl-regular value">{productDetail.brand}</span>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <span className="font-xl-regular">Quality: </span>
                                    <span className="font-xl-regular value">{productDetail.quality}</span>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <span className="font-xl-regular">Application Type: </span>
                                    <span className="font-xl-regular value">{productDetail.application_type}</span>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <span className="font-xl-regular">Coverage(sqft): </span>
                                    <span className="font-xl-regular value">{Number(productDetail.coverage_in_sqft)}</span>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <span className="font-xl-regular">Make Type: </span>
                                    <span className="font-xl-regular value">{productDetail.make_type}</span>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <span className="font-xl-regular">Coverage(sqmt): </span>
                                    <span className="font-xl-regular value">{Number(productDetail.coverage_in_sqm)}</span>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <span className="font-xl-regular">Surface Type: </span>
                                    <span className="font-xl-regular value">{productDetail.surface_type}</span>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <span className="font-xl-regular">Coverage/pc(sqft): </span>
                                    <span className="font-xl-regular value">{productDetail.coverage_per_piece_in_sqft}</span>
                                </div>
                                <div className="col-xs-12 col-sm-4">
                                    <span className="font-xl-regular">Body Type: </span>
                                    <span className="font-xl-regular value">{productDetail.body_type}</span>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <span className="font-xl-regular">Coverage/pc(sqmt): </span>
                                    <span className="font-xl-regular value">{productDetail.coverage_per_piece_in_sqm}</span>
                                </div>
                            </div>
                            {
                                productDetail?.linked_products?.length > 0 && 
                                <div className="font-2xl-bold mt-4">Linked Products</div>
                            }
                            <div className="row linked_products">
                                {
                                    productDetail?.linked_products?.map(product => (
                                        <div className="col-xs-12 col-sm-6 my-6" key={product.linked_product}>
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 pr-0">
                                                        {product.image && <img src={product.image} alt="product" />}
                                                    </div>
                                                    <div className="col-6 col-sm-6 pl-4">
                                                        <div className="card__body">
                                                        <span className="action">
                                                            <Popconfirm
                                                                title="Are you sure to unlink this product?"
                                                                onConfirm={() => unlinkProduct(product.linked_product)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <a href="#">
                                                                    <IonIcon name='Delete Category' icon={closeCircleOutline} /> Unlink
                                                                </a>
                                                            </Popconfirm>
                                                        </span>
                                                            <div className="card__body__header font-xl-bold uppercase">
                                                                {product.name}
                                                            </div>
                                                            <div className="card__body__header font-xl-regular">Pack of {product.packing_per_box}</div>
                                                            <div className="card__body__header font-xl-regular uppercase">{product.brand}</div>
                                                            <Link to={`/${type}/${product.category}/products/${product.linked_product}`}>
                                                                <div className="card__link text-right mt-4">
                                                                    View Product 
                                                                    <span className="icon"><IonIcon icon={arrowForwardCircleOutline}></IonIcon></span>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                userDetail?.permission !== 'Viewer' ?
                                <div className='action mb-4 pb-4'>
                                    <Link className="font-2xl-bold mt-4 log-btn" to={`/${type}/${category}/products/${productId}/logs`}>Logs</Link>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            }
            <Modal  
                width={500}
                closable={false} 
                visible={showLinkModal} 
                okText="Link Products"
                onOk={linkProducts} 
                wrapClassName="linked-products"
                onCancel={() => setShowLinkModal(false)}>
                <Form
                    name="basic"
                    layout="vertical"
                >
                    <Form.Item
                        label="Link Product"
                    >
                        <Select
                            showSearch
                            mode="multiple"
                            placeholder="Select Product"
                            filterOption={false}
                            onSearch={handleTextChange}
                            onChange={(val) => setSelectedProducts(val)}
                            searchValue={textInput}
                            loading={linkProductLoading}
                            value={selectedProducts}
                            notFoundContent={linkProductLoading ? <Spin size="small" /> : 'No product found.' }
                            tagRender={(props) => <Tag 
                                closable={props.closable}
                                onClose={props.onClose}>{props.label}</Tag>}
                        >
                            {
                                (productOptions||[]).map(prod => 
                                    <Option value={prod.id} label={prod.name} key={prod.id}>
                                        <span><img className="link-image" src={prod?.tile_images[0]?.image} alt="" /></span><span>{prod.name}</span>
                                    </Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            
        </div>
    )
}

export default ProductDetail;