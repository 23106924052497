import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { API } from '../constants/api.constant';
import useHTTP from '../hooks/http';

import useToastr from '../hooks/toastr';
import Utils from '../services/utils.service';
import { updateAuthInfo } from '../../../app.reducer';
import LocalLoader from '../components/local-loader/local-loader.component';

export const AuthContext = React.createContext({
  userLoggedIn: false,
  username: null,
  userEmail: null,
  userDetail: null,
  login: () => {},
  signout: () => {},
});

const AuthContextProvider = (props) => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  let history = useHistory();
  const $http = useHTTP();
  const dispatch = useDispatch();
  const toastr = useToastr();
  const _isDebug = Utils.parseBool(process.env.REACT_APP_DEBUG);

  useEffect(() => {
    loginHandler();
  });

  const setCookie = (data) => {
    let d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    Utils.setCookie("ss_user", JSON.stringify(data), d);
  };

  const getRefreshToken = () => {
    const { refresh } = Utils.readCookie(
      process.env.REACT_APP_ACCOUNT_COOKIE
    );
    $http
    .post(`${API.POST.TOKEN_REFRESH}`, {
      refresh
    })
    .then((res) => {
      setCookie({
        ...getCookie(),
        access: res.access,
      });
      // getUserDetails();
      window.location.reload();
    })
    .catch((err) => {
      if (err && (err.status === 403 || err.status === 401 || err.status === 400)) {
        setUserLoggedIn(false);
        tokenInvalidator('logout');
      }
    });
  }

  const getUserDetails = () => {
      setIsLoading(false);
      setUserDetail(getCookie());
      $http
        .get(API.GET.GET_USER_INFO)
        .then((res) => {
          if (res && res.email) {
            setUserEmail(res.email);
            setUserDetail(res);
          }
          if (!res?.merchant?.id) {
            history.push('/account/details');
          }
          if (Object.keys(res).length) {
            dispatch(
              updateAuthInfo({
                ...res,
                username: getCookie().name,
              })
            );
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          if (err && (err.status === 403 || err.status === 401)) {
            getRefreshToken();            
          }
          // signoutHandler();
        });
  }

  useEffect(() => {
    if (isUserLoggedIn()) {
      // const userDetail = getCookie();
      // dispatch(
      //   updateAuthInfo(userDetail)
      // );
      // setUserDetail(userDetail);
      getUserDetails();
    } else {
      tokenInvalidator('login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function tokenInvalidator(action) {
    if (!showLogin) {
      redirectToAccounts(action);
    }
  }

  function showNotification(type) {
    if (!_isDebug) {
      if (type === 'session_expired') {
        // toastr.info('Session expired. Login again to continues');
      } else if (type === 'permission_error') {
        toastr.error('Failed to fetch user permissions. Contact administrator');
      } else if (type === 'restricted_access') {
        toastr.error(
          'You do not have enough access for this operation. Contact administrator'
        );
      } else {
        toastr.error('Something went wrong. Contact administrator');
      }
    }
  }

  function redirectToAccounts() {
    let authPortalPath = '/account/login';
    if (!_isDebug) {
      setIsLoading(false);
      setShowLogin(true);
      if (window.location.href.indexOf('/account') === -1) {
        const url = `${authPortalPath}?returnUrl=${window.location.href}`;
        history.push(url, '_self');
      }
    } else {
      toastr.info(
        `Redirected to ${authPortalPath}?returnUrl=${window.location.href}`
      );
    }
  }

  function getCookie() {
    let userCookie = null;
    try {
      userCookie = Utils.readCookie(process.env.REACT_APP_ACCOUNT_COOKIE);
      if (userCookie) {
        if (!userCookie.hasOwnProperty('access')) {
          showNotification('session_expired');
          tokenInvalidator('logout');
        }
      } else {
        showNotification('session_expired');
        tokenInvalidator('login');
      }
    } catch (error) {
      toastr.error(`Error occurred in parsing cookie`, { toastId: 13 });
      showNotification('session_expired');
      tokenInvalidator('logout');
    }
    return userCookie;
  }

  function getValFromCookie(key) {
    let result = null;
    const userCookie = getCookie();
    if (userCookie && userCookie.hasOwnProperty('name')) {
      setUsername(userCookie.name);
    }
    if (key && userCookie && userCookie.hasOwnProperty(key)) {
      result = userCookie[key];
    }
    return result;
  }

  function isUserLoggedIn() {
    const token = getValFromCookie('access');
    return !!token;
  }

  const loginHandler = () => {
    setUserLoggedIn(isUserLoggedIn());
  };

  const signoutHandler = () => {
    const token = getValFromCookie('access');
    if (!token) {
      setUserLoggedIn(false);
      tokenInvalidator('logout');
      return;
    }
    Utils.deleteCookie(process.env.REACT_APP_ACCOUNT_COOKIE);
    setUserLoggedIn(false);
    tokenInvalidator('logout');
    // $http_account
    //   .put(API.PUT.LOGOUT)
    //   .then((res) => {
    //     Utils.deleteCookie(process.env.REACT_APP_ACCOUNT_COOKIE);
    //     setUserLoggedIn(false);
    //     tokenInvalidator('logout');
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toastr.error('Something went wrong. Contact administrator');
    //   });
  };

  return (
    <AuthContext.Provider
      value={{
        login: loginHandler,
        signout: signoutHandler,
        userLoggedIn: userLoggedIn,
        username: username,
        userEmail: userEmail,
        userDetail: userDetail,
      }}
    >
      {isLoading ? <LocalLoader loader={true} /> : props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
