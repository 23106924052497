import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AuthContext } from "../../context/auth.context";
import { authInfo } from "../../../../app.reducer";
import logo from "../../../../assets/logo.jpeg";
import "./header.scss";

export function Header(props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [headers, setHeaders] = useState([]);
  const authContext = useContext(AuthContext);
  const { pathname } = useLocation();
  const signout = () => {
    authContext.signout();
  };

  return (
    <nav className="navigation">
      <Link to="/" className="brand-name">
        <img
          className="navigation__logo d-inline-block"
          src={logo}
          style={{ padding: 4 }}
          alt="logo"
        />
      </Link>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from Heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="#ea5700"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <Link to="/">Category</Link>
          </li>
          {authContext?.userDetail?.permission === "Admin" && (
            <li
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <Link to="/staffs">Manage Staff</Link>
            </li>
          )}
          {authContext?.userDetail?.permission === "Admin" && (
            <li
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              <Link to="/profile">Merchant Profile</Link>
            </li>
          )}
          <li>
            <a className="dropdown-item" onClick={signout}>
              Sign Out
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
