import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form, Input, Radio } from 'antd';
import { IonIcon } from '@ionic/react';
import { arrowBackCircleOutline } from 'ionicons/icons';

import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import './add-category.scss';


const { TextArea } = Input;

const AddCategory = () => {
    const history = useHistory();
    const toastr = useToastr();
    const [form] = Form.useForm();
    const {type } = useParams();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const [defaults, setDefaults] = useState({});

  useEffect(() => {
    fetchDefaultOptions();
  }, []);

  const fetchDefaultOptions = () => {
    $http
      .get(`${process.env.REACT_APP_API_URL}/${type}/categories/default/`)
      .then((res) => {
        const defaults = {
            make_types: res.make_type, 
            sizes: res.size, 
            // original: res.results,
        };
        // res.results.forEach(element => {
            
        //   element.make_type && defaults.make_types.add(element.make_type);
        //   element.size && defaults.sizes.add(element.size);
        // });
        setDefaults(defaults);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
      });
  }

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const addCategory = (values) => {
    console.log(values);
    // const category = defaults.original.find(ele => ele.size === values.size && ele.make_type == values?.make_type);
    if (values.size) {
        const data = { 
          size_id: values.size,
        };
        if (values.make_type) {
          data.make_type_id = values.make_type;
        }
          
          $http
              .post(`${process.env.REACT_APP_API_URL}/${type}/categories/`, data)
              .then((res) => {
                  history.push(`/${type}/categories`);
              })
              .catch((err) => {
                  console.log(err);
                  toastr.error(err.data.message);
              });
    } else {
        toastr.error("Please select correct combinations!");
    }
    
    
  };

  const resetForm = () => {
    form.resetFields();
  }

  const goBack = () => {
    history.goBack();
  }

  return (
    <>
      <div className="category-form">
        <div className="row">
            <div className="col-sm-12 col-xs-12 back-btn">
                <button className="link" onClick={goBack}><span className="icon"><IonIcon icon={arrowBackCircleOutline}></IonIcon></span> Back</button>
            </div>
        </div>
        <div className="row my-4 px-0">
          <div className="col-sm-12 col-xs-12 font-3xl-semibold">Add Category</div>
        </div>
        
        <Form
            name="basic"
            layout="vertical"
            form={form}
            onFinish={addCategory}
            onFinishFailed={onError}
        >
            <Form.Item
                label="Size"
                name="size"
                rules={[{ required: true, message: 'Please select size!' }]}
            >
                <Radio.Group>
                    {
                        [...defaults?.sizes??[]].map((size, index) => (
                            <Radio value={size.id} key={index}> {size.size} </Radio>
                        ))
                    }
                </Radio.Group>
            </Form.Item> 
            <Form.Item
                label="Make Type"
                name="make_type"
            >
                <Radio.Group>
                    {
                        [...defaults?.make_types??[]].map((make_type, index) => (
                            <Radio value={make_type.id} key={index}> {make_type.make_type} </Radio>
                        ))
                    }
                </Radio.Group>
            </Form.Item>     
            <Form.Item>
                <button 
                    type="button" 
                    className="btn btn-reset font-2xl-regular"
                    onClick={resetForm}
                >
                    Reset
                </button>
                <button type="submit" className="btn btn-submit font-2xl-regular">
                    {'ADD'}
                </button>
            </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default AddCategory;