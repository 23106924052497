import React, {useState, useEffect, useContext} from 'react';
import { Form, Input, Select } from 'antd';
import { useHistory,Link,useLocation } from 'react-router-dom';
import logo from '../../../assets/logo.jpeg';
import { API } from '../../common/constants/api.constant';
import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
//import { AuthContext } from '../../common/context/auth.context';
import { AuthContext } from '../../../modules/common/context/auth.context';
import '../account.scss';
//import  Header  from '../../common/components/header';

export const Merchant = () => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    //const authContext = useContext(AuthContext);
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const signout = () => {
        authContext.signout();
        window.location.href = '/account/login';
    };

    useEffect(() => {
        if (!authContext.userLoggedIn) {
            history.push(`/account/login`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    const getLocation = (url, key) => {
        return new Promise((resolve, reject) => {
            $http
            .get(`${url}`)
            .then((res) => {
                const results = res.map((ele) => ({
                    label: key ? ele[key] : ele.name,
                    value: ele.id,
                }));
                resolve(results);
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
        })
        
  }

    useEffect(() => {
        getLocation(API.GET.COUNTRIES).then(res => setCountries(res));
    }, []);

    const onError = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };
    
    const signup = (values) => {
        if (values.phone) {
            values.phone_number = `+91${values.phone}`;
        }
        
        $http
            .post(`${API.POST.MERCHANT}`, values)
            .then((res) => {
                const message = `Details saved successfully!`;
                toastr.info(message);
                window.location.href= '/';
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
    };

    const onCountryChange = (value) => {
        getLocation(`${API.GET.REGIONS}?country_id=${value}`).then(res => {
            setRegions(res);
            setCities([]);
        });
    }

    const onRegionChange = (value) => {
        getLocation(`${API.GET.CITIES}?region_id=${value}`, 'name_ascii').then(res => {
            setCities(res);
        });
    }


    return (
        <>
        {/* <Header signout={"signout"}  /> */}
        <div className="product-form">
         <div className="row my-4 px-0">           
            <div className="col-sm-12 col-xs-12 text-right font-3xl-bold back-btn" style={{marginTop:-50,marginLeft:180}}>
                <button className="link" onClick={signout}>
                Sign Out
                </button>
            </div>
            
            <div className="col-sm-12 col-xs-12 text-center font-3xl-semibold px-0" style={{marginTop:20}}>Enter Details</div>
            <div className="col-sm-12 col-xs-12 text-center font-lg-regular subtitle">Enter your details below</div>
            </div>
        
            <Form
              name="basic"
              layout="vertical"
              onFinish={signup}
              onFinishFailed={onError}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter your name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    initialValue={authContext?.userDetail?.email}
                    rules={[
                        { type: 'email', message: 'The input is not valid E-mail!' },
                        { required: true, message: 'Please enter your email!' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Phone Number"
                    name="phone"
                    placeholder="Enter phone number"
                    initialValue={authContext?.userDetail?.phone}
                    rules={[
                        { pattern: /^\d{10}$/, message: 'Please enter a valid phone number' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Website"
                    name="website"
                    rules={[
                        { type: 'url', message: 'Please enter valid website address!' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="address1"
                >
                    <Input.TextArea />
                </Form.Item>   
                 
                <Form.Item
                    label="PIN CODE"
                    name="postal_code"
                    rules={[
                        { required: true, message: 'Please enter your pin code!' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                        { required: true, message: 'Select your country!' },
                    ]}
                >
                    <Select
                        options={countries}
                        showSearch={true}
                        optionFilterProp='label'
                        onChange={onCountryChange}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="State"
                    name="region"
                    rules={[
                        { required: true, message: 'Select your state!' },
                    ]}
                >
                    <Select
                        options={regions}
                        showSearch={true}
                        optionFilterProp='label'
                        onChange={onRegionChange}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="City"
                    name="city"
                    rules={[
                        { required: true, message: 'Please enter your city!' },
                    ]}
                >
                    <Select
                        options={cities}
                        showSearch={true}
                        optionFilterProp='label'
                        onChange={onRegionChange}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="GSTIN"
                    name="gstin"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="PAN"
                    name="pan"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <button type="submit" className="btn btn-submit btn-login font-2xl-regular">
                        Save
                    </button>
                </Form.Item>
            </Form>

        </div>
        </>
    )
}